(function($) {
  $(document).ready(function() {
    var newmag = $('.nav-cnt').hasClass('new-label');
    if (newmag) {
      $('.another-title').hide();
    } else {
      $('.last-title').hide();
    }

    var showAll = {
      'container': $('.mag-no-list'),
      'list': $('.mag-no-list > div > ul'),
      'currentHeight': function() {
        return showAll.list.outerHeight() + 2; //border
      },
      'starterHeight': function() {
        return $('.show-cont').outerHeight();
      },
      'setStarterHeight': function() {
        showAll.container.height(showAll.starterHeight());
      },
      'opened': false,
      handler: $('.show-all-magazines'),
      handleList: function() {
        if (showAll.opened) {
          showAll.setStarterHeight();
        } else {
          showAll.container.height(showAll.currentHeight())
        }
        showAll.opened = !showAll.opened;
        showAll.handler.toggleClass('opened');
      },
      init: function() {
        showAll.setStarterHeight();
        var activeIndex = showAll.list.find('.active').index(),
          windowWidth = $(window).width();
        if (activeIndex >= 10 && windowWidth > 1024) {
          showAll.handleList();
          return;
        }
        if (activeIndex >= 5 && windowWidth < 1024 && windowWidth > 662) {
          showAll.handleList()
          return;
        }
        if (activeIndex > 3 && windowWidth < 662) {
          showAll.handleList();
          return;
        }
      }
    };

    showAll.handler.on({click: showAll.handleList});
    showAll.init();

    $(window).on({
      resize: function() {
        showAll.handler.removeClass('opened');
        showAll.opened = false;
        showAll.setStarterHeight();
      }
    });

  });

})(jQuery);
